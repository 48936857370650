.button {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #006600;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 5px 12px;
  font-size: 18px;
  border-radius: 1rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button:hover {
  color: #fff;
  background-color: #094909;
}