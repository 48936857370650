button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

li {
    list-style: none;
}

.answer {
    padding: 10px;
}

.accordion_item {
    margin: 0;
    padding: 0;
    border: none;
}

.button {
    width: 100%;
    padding: 10px;
    color: white;
    background-color: green;
    display: flex;
    justify-content: space-between;
}

.answer_wrapper {
    font-size: 1.2rem;
    background: #f2f0f0;
    transition: height ease 0.2s;
    height: 0;
    overflow: hidden;
}

.active .button {
    background-color: rgb(0, 113, 0);
  }

@media only screen and (max-width: 480px) {
    .button {
        font-size: 1rem;
    }

    .answer_wrapper {
        font-size: 0.9rem;
    }
}
