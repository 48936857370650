.imageContainer {
    background-color: black;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.header {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    padding: 5rem 15%;
    transform: translate(-50%, -50%);
    margin: auto;
    text-align: center;
}

.header p {
    color: white;
    font-size: 4rem;
}


@media only screen and (max-width: 480px) {
    .header p {
        color: white;
        font-size: 3rem;
    }

    .header {
        padding: 5rem 10%;
    }
  }