.pillarsContainer {
    /* grid stuff */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    column-gap: 75px;
    row-gap: 50px;
    margin:  3% auto 0 auto;
    max-width: 1050px;
    justify-items: center;
}

.gridItem {
    max-width: 470px;
    align-self: center;
}

.pillarsContainer img {
    border-radius: 20px;
    max-width: 100%;
    height: auto;
}

.title {
 font-size: 2rem;
 padding-bottom: 20px;
 font-family: "Nunito";
 font-weight: 300;
}

.description {
    font-size: 1.25rem;
    font-weight: 200;
}

@media only screen and (max-width: 768px) {
    .pillarsContainer {
        /* grid stuff */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gridItem {
        max-width: none;
    }
}


@media only screen and (max-width: 480px) {
    .pillarsContainer {
        /* grid stuff */
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .title {
        font-size: 1.5rem;
        padding-bottom: 20px;
        font-family: "Nunito";
        font-weight: 300;
       }

       .description {
        font-size: 1rem;
        font-weight: 200;
    }
}
