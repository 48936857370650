.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 0;
    justify-items: center;
}

.gridItem {
    margin: 10px;
}

@media only screen and (max-width: 1375px) {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 0;
        justify-content: center;
    }
  }

@media only screen and (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: 30px 0;
        align-items: center;
    }
  }