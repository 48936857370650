.footerWrapper {
    background-color: #323232;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    padding: 3% 0 0 0;
}

.footer {
    margin: 0 15%;
    padding: 0 0 0 0;
    display: flex;
    justify-content: space-between;
}

.rightWrapper {
    display: flex;
}

.navLinks {
    list-style: none;
    margin: 0 30px;
}

.navLinks li {
    margin-bottom: 6px;
}

.icons img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.PSA {
    font-size:24px;
    font-weight:300;
    max-width:225px;
}

.copyright {
    font-size: 12px;
    margin: 0 15%;
    padding: 2% 0;
}

@media only screen and (max-width: 768px) {
    .footerWrapper {
        padding: 6% 0 0 0;
    }

    .footer {
        flex-direction: column;
        padding: 0;
        gap: 30px 0;
        align-items: flex-start;
        margin: 0 10% 15px 10%;
        padding: 0 0 0 0;

    }

    .copyright {
        margin: 0 10%;
    }

    .navLinks {
        list-style: none;
        margin: 0 0;
    }

    .rightWrapper {
        width: auto;
        gap: 0 30px;
    }

    .footerWrapper {
        font-size: 1rem;
        font-weight: 200;
    }
}

@media only screen and (max-width: 480px) {
    .footer {
        flex-direction: column;
        gap: 30px 0;
        align-items: flex-start;
        margin: 0 10% 15px 10%;
    }

    .copyright {
        margin: 0 10%;
    }

    .navLinks {
        list-style: none;
        margin: 0 0;
    }

    .rightWrapper {
        width: auto;
        gap: 0 30px;
    }

    .footerWrapper {
        font-size: 1rem;
        font-weight: 200;
    }

    .PSA {
        font-size:20px;
        font-weight:300;
    }
}