.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 8px 2px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  width: 300px;
}
.cardHeader img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.cardBody {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 75px;
  background: white;
}

.cardBody p {
  font-size: 16px;
  margin-bottom: 2px;
}
