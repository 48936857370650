.sectionContainer {
  padding: 5% 15%;
  font-size: 1.5rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 768px) {
  .sectionContainer {
    padding: 10% 5%;
  }
}

@media only screen and (max-width: 480px) {
  .sectionContainer {
    padding: 10% 5%;
  }
}
