* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.PSA {
    color: white;
}

.wrapper {
    background-color: rgb(0, 0, 0, 0.0);
    font-family: 'Nunito', sans-serif;
    position: absolute;
    z-index: 1;
    width: 100%;
}

.navbar {
    display: flex;
    justify-content: space-between;
    margin: 2% 10%;
    height: 80px;
}

.navLinkGroup {
    display: flex;
    align-items:center;
}

.navLinks {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navLinks a {
    font-size: 18px;
}

.navLink {
    padding: 20px;
}

a {
    text-decoration: none;
    color: white
}

.mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .navbar {
        align-items: center;
    }

    .mobile {
        display: inline;
        color: white;
    }

    .navLinkGroup {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 200px;
        background-color: rgb(0, 128, 0, 0.8);
        transform: translate(200px, 0);
        transition: all 0.5s ease-in-out;
    }

    .isClicked {
        transform: translate(0px, 0px);    
    }

    .navLinks {
        display: flex;
        flex-direction: column;
    }
    
    .closeIcon {
        position: absolute;
        color: white;
        top: 25px;
        right: 25px;
    }
}

@media only screen and (max-width: 480px) {
    .navbar {
        align-items: center;
    }

    .mobile {
        display: inline;
        color: white;
    }

    .navLinkGroup {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 150px;
        background-color: rgb(0, 128, 0, 0.8);
        transform: translate(150px, 0);
        transition: all 0.5s ease-in-out;
    }

    .isClicked {
        transform: translate(0px, 0px);    
    }

    .navLinks {
        display: flex;
        flex-direction: column;
    }
    
    .closeIcon {
        position: absolute;
        color: white;
        top: 25px;
        right: 25px;
    }
}