@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&family=Raleway:wght@200;400;600&display=swap');
* {
  padding:0;
  margin:0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', 'sans-serif';
}